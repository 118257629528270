<template>
  <v-snackbar color="error" v-model="display" multi-line>
    {{ info }}
    <template v-slot:action="{ attrs }">
      <v-btn color="white" text v-bind="attrs" @click="closePopup"> Close </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'snackbar',
  props: {
    snackbar: {
      type: Boolean,
      default: null
    },
    message: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      display: this.snackbar,
      info: this.message
    }
  },
  watch: {
    snackbar: {
      handler (value) {
        this.display = value
      },
      immediate: true
    },
    message: {
      handler (value) {
        this.info = value
      },
      immediate: true
    }
  },
  methods: {
    closePopup () {
      this.display = false
      this.info = ''
      this.$emit('click', false)
    }
  }
}
</script>

<style scoped>

</style>
