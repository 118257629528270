<template>
  <div>
    <v-card-text :class="isPc ? 'pc-title' : 'p-t-24 bold-f-s'"> Please confirm/update your information </v-card-text>
    <div :class="isPc ? 'pc-containers' : 'mb-containers'">
      <div :class="isPc ? 'pc-contents' : 'mb-contents profile'">
        <v-row class="m-0">
          <v-col v-for="(field, index) in layoutSetting" :key="index" :cols="field.cols">
            <ValidationProvider
                ref="observer"
                :name="field.title"
                :rules="field.rules"
                v-slot="{ errors }"
                class="width-full"
            >
              <v-text-field
                  v-if="field.filedKey === 'ssn'"
                  height="20"
                  type="input"
                  disabled
                  :label="field.title"
                  v-model="personal[field.filedKey]"
                  v-mask="field.mask"
                  :error-messages="errors[0]"
              ></v-text-field>
              <v-text-field
                  v-else-if="field.filedKey === 'homePhone' || field.filedKey === 'cellPhone'"
                  class="f-s-20"
                  height="20"
                  type="input"
                  :label="field.title"
                  v-mask="field.mask"
                  v-model="personal[field.filedKey]"
                  :error-messages="errors[0]"
              ></v-text-field>
              <v-menu
                  v-else-if="field.filedKey === 'birthday'"
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  min-width="290"
                  offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="personal[field.filedKey]"
                      height="20"
                      :label="field.title"
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      readonly
                      :error-messages="errors[0]"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date" no-title scrollable>
                  <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
                  <v-btn text color="primary" @click="updateBirthday()"> OK </v-btn>
                </v-date-picker>
              </v-menu>
              <v-select
                  v-else-if="field.filedKey === 'state'"
                  height="20"
                  :items="field.options[field.filedKey]"
                  :label="field.title"
                  v-model="personal[field.filedKey]"
                  :error-messages="errors[0]"
              ></v-select>
              <v-text-field
                  v-else
                  height="20"
                  :class="field.class"
                  type="input"
                  :label="field.title"
                  v-model="personal[field.filedKey]"
                  :error-messages="errors[0]"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>
      </div>
    </div>
    <v-card-actions :class="isPc ? 'pc-pages-action' : 'mb-pages-action'">
      <v-btn large color="primary" class="m-0 f-s-18" :width="isPc ? '240px' : '96vw'" @click="next"> Next </v-btn>
    </v-card-actions>
    <Snackbar :snackbar="snackbar.display" :message="snackbar.message" @click="closePopup"></Snackbar>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { DataType, Tool } from '@/util/index.js'
import { OriginationApi } from '@/api/application'
import Snackbar from '@/views/common/Snackabr'

const tool = new Tool()
export default {
  props: {
    isPc: {
      type: Boolean,
      default: true
    }
  },
  components: { ValidationProvider, Snackbar },
  data () {
    return {
      page: DataType.PAGES.PERSONAL,
      snackbar: {
        display: false,
        message: ''
      },
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      layoutSetting: [
        { title: 'First Name', filedKey: 'firstName', rules: 'required', cols: 4, class: 'input-text-uppercase' },
        { title: 'Middle Name', filedKey: 'middleName', cols: 4, class: 'input-text-uppercase' },
        { title: 'Last Name', filedKey: 'lastName', rules: 'required', cols: 4, class: 'input-text-uppercase' },
        { title: 'Date of Birth', filedKey: 'birthday', rules: 'required', cols: 6 },
        { title: 'SSN', filedKey: 'ssn', mask: '***-**-####', cols: 6 },
        { title: 'Home Phone', filedKey: 'homePhone', mask: '(###) ###-####', rules: 'phone', cols: 6 },
        { title: 'Cell Phone', filedKey: 'cellPhone', mask: '(###) ###-####', rules: 'phone', cols: 6 },
        { title: 'Primary Email', filedKey: 'email', rules: 'email', cols: 6, class: 'input-text-lowercase' },
        {
          title: 'Alternative Email',
          filedKey: 'alternateEmail',
          rules: 'email',
          cols: 6,
          class: 'input-text-lowercase'
        },
        { title: 'State', filedKey: 'state', options: { state: DataType.State }, rules: 'required', cols: 4 },
        { title: 'City', filedKey: 'city', rules: 'required', cols: 4, class: 'input-text-capitalize' },
        { title: 'Zip', filedKey: 'zip', rules: 'zip', cols: 4, class: 'input-text-capitalize' },
        { title: 'Address', filedKey: 'address', rules: 'required', cols: 12, class: 'input-text-capitalize' }
      ],
      sourcePersonal: {},
      personal: {
        firstName: '',
        middleName: '',
        lastName: '',
        birthday: '',
        ssn: '',
        city: '',
        zip: '',
        address: '',
        state: '',
        email: '',
        alternateEmail: '',
        cellPhone: '',
        homePhone: ''
      }
    }
  },
  created () {
    const _this = this
    _this.$store.commit('setCurrentPage', this.page)
    _this.$store.commit('setOverlay', true)
    _this.sourcePersonal = JSON.parse(localStorage.getItem(DataType.COOKIE_KEY.PERSONAL))
    for (const key in this.personal) {
      const value = this.sourcePersonal[key]
      if (key === 'ssn') {
        _this.personal[key] = value.substr(5)
      } else {
        _this.personal[key] = value
      }
    }
    _this.date = new Date(_this.personal.birthday).toISOString().substr(0, 10)

    // 跳转页面
    OriginationApi.pageControl(null, function (pageControl) {
      if (
        tool.isNotEmpty(pageControl) &&
          pageControl.indexOf(localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO_ID)) > 0
      ) {
        _this.page.next = 'EMPLOYMENT'
      }
    })

    _this.$store.commit('setOverlay', false)
  },
  methods: {
    closePopup () {
      this.snackbar.display = false
      this.snackbar.message = ''
    },
    next () {
      const _this = this
      _this.$store.commit('setOverlay', true)
      if (!tool.verifyCustomerInformation(_this.personal).display) {
        _this.$store.commit('setOverlay', false)
        _this.snackbar.display = true
        _this.snackbar.message = tool.verifyCustomerInformation(_this.personal).message
        return false
      }
      const updatePersonal = {}
      for (const key in this.personal) {
        const value = tool.formatConversion(key, this.personal[key])
        if (key === 'state' || key === 'zip' || key === 'email') {
          if (value) {
            updatePersonal[key] = value
          }
        }
        if (value !== this.sourcePersonal[key]) {
          updatePersonal[key] = value
          this.sourcePersonal[key] = value
        }
      }
      if (tool.isEmpty(this.personal.address.replace(/[0-9]/gi, ''))) {
        this.$swal({
          type: 'info',
          text: 'The address is full of numbers!',
          showCancelButton: true,
          showConfirmButton: true,
          cancelButtonText: 'No',
          confirmButtonText: 'Yes'
        }).then((res) => {
          if (res.value) {
            _this.checkPersonalState(updatePersonal)
          } else {
            _this.$store.commit('setOverlay', false)
          }
        })
      } else {
        _this.checkPersonalState(updatePersonal)
      }
      _this.$eventBus.$emit('closeCountDown')
    },
    updateBirthday () {
      this.personal.birthday = this.date
      this.menu = false
    },
    checkPersonalState (updatePersonal) {
      const _this = this
      const dataStr = {
        loanId: localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID),
        checkJson: {
          state: _this.personal.state
        }
      }
      OriginationApi.checkPersonalState(
        dataStr,
        function () {
          _this.doUpdate(updatePersonal)
        },
        function () {
          if (tool.isNotEmpty(updatePersonal) && Object.prototype.hasOwnProperty.call(updatePersonal, 'ssn')) {
            delete updatePersonal.ssn
          }

          if (tool.isNotEmpty(updatePersonal)) {
            const loanId = localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID)
            updatePersonal.loanId = loanId
            OriginationApi.updateOrigination(
              { feature: 'personal', data: updatePersonal },
              function () {},
              function (error) {
                _this.getErrorCode(error.code)
              }
            )
          }
          const code = ['4CEA288ECFC282EB862726283F92EB98']
          const statusData = {
            feature: 'loan-status',
            data: {
              loanId: localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID),
              loanStatus: DataType.LoanStatus.NEGATIVE_WITHDRAWN.value,
              withdrawCode: DataType.WithdrawCodeEnum.NO_BUSINESS_STATE.value,
              withdrawnReasonText: DataType.WithdrawCodeEnum.NO_BUSINESS_STATE.text,
              denyCode: JSON.stringify(code)
            }
          }
          OriginationApi.updateOrigination(
            statusData,
            function () {},
            function () {}
          )
          _this.$router.push('/instant/notification/-400')
        }
      )
    },
    getErrorCode (errorCode) {
      switch (errorCode) {
        case DataType.UpdateErrorCode.ZIP_CODE_AND_STATE_ERROR_CODE.value:
          this.$swal({
            type: 'warning',
            text: 'The zip code is not matched with state, please update one of them!',
            showConfirmButton: true,
            confirmButtonText: 'OK'
          }).then(() => {
            this.$store.commit('setOverlay', false)
          })
          break
        case DataType.UpdateErrorCode.MIL_AND_GOV_EMAIL_ERROR_CODE.value:
          this.$router.push('/instant/notification/-400')
          break
        default:
          this.$router.push('/instant/notification/-400')
          break
      }
    },
    doUpdate (updatePersonal) {
      const _this = this
      if (tool.isNotEmpty(updatePersonal) && Object.prototype.hasOwnProperty.call(updatePersonal, 'ssn')) {
        delete updatePersonal.ssn
      }

      if (tool.isNotEmpty(updatePersonal)) {
        const loanId = localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID)
        updatePersonal.loanId = loanId
        OriginationApi.updateOrigination(
          { feature: 'personal', data: updatePersonal },
          function (result) {
            localStorage.setItem(DataType.COOKIE_KEY.PERSONAL, JSON.stringify(_this.sourcePersonal))
            _this.$store.commit('setOverlay', false)
            const stepMessage = {
              currect: DataType.PAGES.PERSONAL,
              next: DataType.PAGES.MILITARY
            }
            _this.$eventBus.$emit('sendStepToSloth', stepMessage)
            OriginationApi.enteredAndLeft(
              loanId,
              DataType.PAGES.PERSONAL.step,
              DataType.PAGES[_this.page.next].step,
              localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO_ID)
            )
            if (_this.isPc) {
              _this.$router.push(DataType.PAGES[_this.page.next].addressPC)
            } else {
              _this.$router.push(DataType.PAGES[_this.page.next].address)
            }
          },
          function (error) {
            _this.getErrorCode(error.code)
          }
        )
      } else {
        const stepMessage = {
          currect: DataType.PAGES.PERSONAL,
          next: DataType.PAGES.MILITARY
        }
        _this.$eventBus.$emit('sendStepToSloth', stepMessage)
        OriginationApi.enteredAndLeft(
          localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID),
          DataType.PAGES.PERSONAL.step,
          DataType.PAGES[_this.page.next].step,
          localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO_ID)
        )
        if (_this.isPc) {
          _this.$router.push(DataType.PAGES[_this.page.next].addressPC)
        } else {
          _this.$router.push(DataType.PAGES[_this.page.next].address)
        }
      }
    }
  }
}
</script>
<style lang="css" scoped>
.profile input {
  text-align: left !important;
  font-size: 16px !important;
}

.profile .input-text-uppercase input {
  text-transform: uppercase;
}

.profile .input-text-lowercase input {
  text-transform: lowercase;
}

.profile .input-text-capitalize input {
  text-transform: capitalize;
}
</style>
